<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 class="text-3xl font-bold tracking-tight text-gray-900">Can't find what you're looking for?</h2>
          <p class="mt-4 text-lg text-gray-500">Reach out to our <a href="mailto:support@complaia.com" class="font-medium text-indigo-600 hover:text-indigo-500">customer support</a> team.</p>
        </div>
        <div class="mt-12 lg:col-span-2 lg:mt-0">
          <dl class="space-y-12">
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                INTRODUCTION
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                These are the terms and conditions governing the use of https://complaia.com, (the Website) and the agreement that operates between us and you (the Terms). The Website is owned and operated by COMPLAIA SYSTEMS SP. Z O.O. (us/our/we) and these Terms set out the rights and obligations of all users (you/your) in relation to your use of the Website.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Please read carefully these Terms and our Privacy Statement. By using the Website, you are consenting to be bound by the current Terms and our Privacy Statement. We may revise the Terms and information contained on the Website at any time and without notice. If you do not agree to these Terms or the Privacy Statement, please refrain from using the Website.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                CONDUCT OF USE
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                You are not allowed to use this website for any purpose that is unlawful or prohibited by these terms and conditions. You accept not to use this website in any way that is unlawful, abusive, threatening, harassing, obscene, libellous, hateful, or in any other way violating these terms and conditions.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                LIABILITY
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                The material contained within the courses on the Website is provided without any guarantees, conditions or warranties as to its accuracy. COMPLAIA SYSTEMS SP. Z O.O. does not represent that information contained on or available via the Website is accurate or complete and accordingly, it should not be relied on as such. You should not rely on any such information. Any arrangements made between you and any other person, using or named on the Website are entirely at your sole risk and responsibility.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                To the extent permitted by law, we, and third parties connected to us hereby expressly exclude:
                <ol class="list-disc list-inside">
                  <li>all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity;</li>
                  <li>any liability for loss or damage incurred by any user in connection with the use, inability to use, or results of the use of the Website, any websites linked to it and any material posted on it;</li>
                  <li>any liability for any bugs or faults in our systems or tools; and</li>
                  <li> any liability for any direct, special, indirect or consequential loss or damage incurred by any user in connection with the Website or in connection with the use, inability to use, or results of the use of the Website, any websites linked to it and any materials posted on it, including, without limitation any liability for:</li>
                </ol>
                <ol class="list-decimal list-inside">
                  <li>loss of income or revenue;</li>
                  <li>loss of business;</li>
                  <li>loss of profits or contracts;</li>
                  <li>loss of anticipated savings;</li>
                  <li>loss of data;</li>
                  <li>loss of goodwill;</li>
                  <li>wasted management or office time; and</li>
                  <li>for any other loss or damage of any kind, however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable.</li>
                </ol>
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Nothing in this section affects our liability for death or personal injury arising from our negligence, nor our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor any other liability which cannot be excluded or limited under applicable law. 
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                LIMITS OF USE
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                You may use our Website only for lawful purposes. You may not use our Website:
                <ol class="list-disc list-inside mt-2">
                  <li>In any way that breaches any applicable local, national or international law or regulation;</li>
                  <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</li>
                  <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards;</li>
                  <li>To transmit any data, send or upload any material that contains viruses, trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
                </ol>
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Please read carefully these Terms and our Privacy Statement. By using the Website, you are consenting to be bound by the current Terms and our Privacy Statement. We may revise the Terms and information contained on the Website at any time and without notice. If you do not agree to these Terms or the Privacy Statement, please refrain from using the Website.
              </dd>
              <dt class="text-lg font-medium leading-6 text-gray-900 mt-2">
                You also agree:
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                You may use our Website only for lawful purposes. You may not use our Website:
                <ol class="list-disc list-inside">
                  <li>Not to reproduce, duplicate, copy or re-sell any part of our website in contravention of the provisions of these Terms;</li>
                  <li>Not to use ad-blocking software;</li>
                  <li>Not to charge others for the use of COMPLAIA SYSTEMS SP. Z O.O. unless in specific written agreement with COMPLAIA SYSTEMS SP. Z O.O.;</li>
                  <li>Not to reuse text or graphics from the Website or parts thereof.</li>
                </ol>
              </dd>
            </div>

            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                CHANGES TO TERMS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                We are committed to ensuring that our website is as useful and efficient as possible. For that reason, we reserve the right to make changes to the services, at any time. We will never charge you for any service without making it very clear to you precisely what you're paying for.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Any new features which are added to the current site shall also be subject to this Terms and condition. You can always review the most current version of the Terms and conditions at any time on this page. We reserve the right to update, change or retrieve any part of these Terms and conditions by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                SERVICE TERMS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                <ol class="list-inside list-decimal mt-2">
                  <li>COMPLAIA SYSTEMS SP. Z O.O. at this moment grants the User a non-exclusive, non-transferable, limited right to access and use the Service, under the conditions of these Terms & Conditions and for the duration of the Agreement.</li>
                  <li>The use of the Service is at the User's own expense and risk. The User is responsible for meeting the technical and functional requirements and using the electronic communication facilities that are necessary to be able to access and use the Service. The User will at all times bear the risk of loss, theft or damage to any of its data.</li>
                  <li>COMPLAIA SYSTEMS SP. Z O.O. will have the right (but not the obligation), at its sole discretion, to review, edit, limit, refuse or remove Content, or to limit or refuse the User access to the Service. More specifically in the event the use of the Service, according to us, violates these Terms of Use.</li>
                  <li>We may disclose the User's Personal Data or Content, or other data relating to the use of the Service, to third parties where it believes, in good faith, that it is necessary to comply with a court order, ongoing judicial proceeding, criminal or civil subpoena, or other legal process or to exercise its constitutional rights of defense against legal claims.</li>
                </ol>
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                PRICE AND PAYMENT
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                You must pay in the currency in which the Product price is quoted or is selected. If you do not hold an account in the relevant currency you may pay by debit or credit card (or any other method that COMPLAIA SYSTEMS SP. Z O.O. may introduce from time to time) and your card company should exchange the amount charged to the currency of your country at their current rate.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                <ol class="list-inside list-decimal mt-2">
                  <li>Payment may be made by credit card. A debit card or any other such method as COMPLAIA SYSTEMS SP. Z O.O. may introduce at its absolute discretion.</li>
                  <li>COMPLAIA SYSTEMS SP. Z O.O. reserves the absolute right to accept or refuse any payment made in any form.</li>
                  <li>COMPLAIA SYSTEMS SP. Z O.O. cannot guarantee that a particular Service will always be available.</li>
                  <li>To maximize security COMPLAIA SYSTEMS SP. Z O.O. does not hold any credit/debit card details. They are held by third-party payment providers.</li>
                  <li>Your credit card company may also do security checks to confirm it is you making the Order. Your statutory rights are unaffected by these Terms.</li>
                </ol>
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                PERSONAL INFORMATION
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Your submission of personal information through the store is regulated by our Privacy Policy. You are to view our Privacy Policy.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                IFRAMES
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Without prior approval and written permission, you may not create frames around our Web Pages that alter in any way the visual presentation or appearance of our Website.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                INDEMNIFICATION
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                You agree to indemnify, protect and hold harmless to COMPLAIA SYSTEMS SP. Z O.O., subsidiaries, affiliates, partners, officers, directors, agents, contractors, license, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorney’s fees, made by any third party due to or arising out of your breach of these Terms and conditions or the documents they incorporate by reference or your infringement of any law or the rights of a third-party.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                SEVERABILITY
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                In the event that any provision of these Terms and conditions is discovered to be unlawful, null or unenforceable, such provision shall notwithstanding be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be viewed to be cut off from these Terms of Use, such determination shall not affect the credibility and enforceability of any other remaining provisions.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                ENTIRE AGREEMENT
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Our inability to exercise or enforce any right or provision of these Terms of Service shall not constitute a discharge of such right or provision.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                These Terms of Use and any policies or operating rules posted by us on this website or in respect to the Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, pre-empt any prior or synchronous agreements, communications and proposals, whether oral or written, between you and us.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Any ambiguities in the interpretation of these Terms of Use shall not be construed against the drafting party.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                CONTACT INFORMATION
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                If you would like to: access, correct, register a complaint, or simply want more information please contact us:
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Email: hello@complaia.com
              </dd>
            </div>

          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}

</script>
